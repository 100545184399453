import React from 'react';
import $ from 'jquery';
import {Col, Row , Form, FormGroup, Label, Input} from 'reactstrap';

function Signin(props) {

  (function init() {
    window.scrollTo(0, 0)
  })();

  function handleData(e) {

    let email = $("#email").val();
    let password = $("#password").val();

    if(email === "" || password === "" )
    {
        alert("Llenar los campos")
    } else {
      props.onAuth(email, password);
    }
    e.preventDefault();
  }

    return(
            <div>
            <br/><br/>
              <div className="container card">
              <br/><br/>
              <Form>
                <Row form>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                <br/>
                <h2>Bienvenido - Ingresar</h2>
                <br/>
                <FormGroup>
                <Label for="email">Correo electrónico</Label>
                <Input type="email" name="email" id="email" required />
                </FormGroup>
                </Col>
                </Row>

                <Row form>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <FormGroup>
                      <Label for="password">Contraseña</Label>
                      <Input type="password" name="password" id="password" required />
                    </FormGroup>
                </Col>
                </Row>


                <Row>
                  <Col sm="4"></Col>
                  <Col sm="4"></Col>
                  <Col sm="4">
                    <button onClick={(e)=> { handleData(e)}}>Ingresar</button>
                  </Col>
                </Row>

            </Form>
            <br/><br/><br/><br/>
            </div>
            </div>
    )
}

export default Signin;
