import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import SEO from './SEO';
import LandingPage from './app/LandingPage';
import "./app/scss/style.scss";

export default function App() {
  return (

    <BrowserRouter>
      <div>
        <SEO />
        <LandingPage />
      </div>
    </BrowserRouter>
  );
}